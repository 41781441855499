h1.Text_h1__18sYR {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
  display: inline;
  color: #05080d;
}

h3.Text_h3__3MQMP {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
}

h4.Text_h4__3OCr3 {
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
}

h5.Text_h5__2pysS {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
  text-transform: uppercase;
}

h1.Text_h1__18sYR.Text_muted__2zPJ1,
h3.Text_h3__3MQMP.Text_muted__2zPJ1,
h4.Text_h4__3OCr3.Text_muted__2zPJ1,
h5.Text_h5__2pysS.Text_muted__2zPJ1,
p.Text_p__1KdRj.Text_muted__2zPJ1 {
  color: #a3a3a4;
}

p.Text_p__1KdRj {
  margin: 0;
  color: #505050;
}

span.Text_subtitle__3kUdr {
  color: #5e7182;
}

span.Text_success__1FUrr {
  color: #1cd7c3;
}

span.Text_error__3LOSc {
  color: #fd3751;
}

div.Spacer_spacerXsm__71wN1.Spacer_horizontal__A-V06,
div.Spacer_spacerSm__q1yCJ.Spacer_horizontal__A-V06,
div.Spacer_spacerMd__3_aJm.Spacer_horizontal__A-V06,
div.Spacer_spacerLg__3J_KU.Spacer_horizontal__A-V06,
div.Spacer_spacerXLg__Y_pcF.Spacer_horizontal__A-V06
{
    display: inline-block;
    height: 0;
}

div.Spacer_spacerXsm__71wN1 {
    height: 5px;
    width: 5px;
    flex-shrink: 0;
}

div.Spacer_spacerSm__q1yCJ {
    height: 10px;
    width: 10px;
    flex-shrink: 0;
}

div.Spacer_spacerMd__3_aJm {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
}

div.Spacer_spacerLg__3J_KU {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
}

div.Spacer_spacerXLg__Y_pcF {
    height: 80px;
    width: 80px;
    flex-shrink: 0;
}
.Progress_container__2HZVQ {
    display: flex;
}

.Progress_progress__3MD7g {
    height: 10px;
    width: 100px;
    background-color: rgba(28, 215, 195, 0.2);
    margin-right: 2px;
}

.Progress_progress__3MD7g.Progress_completed__3kXO7 {
    background-color: rgba(28, 215, 195, 1.0);
}

.Progress_container__2HZVQ > .Progress_progress__3MD7g:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Progress_container__2HZVQ > .Progress_progress__3MD7g:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 0;
}
.Popover_container__1TNX1 {
    display: inline-block;
    position: relative;
}

.Popover_button__2fXPS {
    cursor: pointer;
}

.Popover_popover__10yhj {
    position: absolute;
    z-index: 999;
    -webkit-animation: Popover_fadein__20Y9N 0.1s ease-in;
            animation: Popover_fadein__20Y9N 0.1s ease-in;
}

.Popover_layover__3f_pP {
    position: absolute;
    z-index: 998;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@-webkit-keyframes Popover_fadein__20Y9N {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes Popover_fadein__20Y9N {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.Label_label__2BwG0 {
    display: inline-block;
    padding-bottom: 5px;
    color: #2F3B47;
    font-size: 14px;
    font-weight: bold;
}
.Select_compactLabel__Vbjup {
  display: flex;
  align-items: center;
}

.Select_label__2YJlM {
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid #bacdd7;
  width: 250px;
  padding: 0 10px;
  border-radius: 3px;
}

.Select_icon__2bu78 {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.Select_compactIcon__2tIbu {
  margin-left: 5px;
}

.Select_itemsContainer__1ma3b {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
  box-shadow: 0 0 5px #dadada;
  min-width: 150px;
  border-radius: 5px;
  background-color: #fff;
}

.Select_item__iI8L_ {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #dbedf6;
  padding: 0 10px;
}

.Select_item__iI8L_ svg {
  font-size: 12px;
}

.Select_item__iI8L_:hover {
  cursor: pointer;
  background-color: #f8fbfd;
}

.Select_disabled__1c9C1 {
  cursor: not-allowed;
  background-color: #f8fbfd;
}

.Button_btn__2P1LO {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: #0631ca;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  height: 50px;
  line-height: 50px;
  min-width: 150px;
  border-radius: 25px;
  padding: 0 30px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 200ms linear;
  outline: none;
}

.Button_link__1I5Q0 {
  color: #0631ca;
  cursor: pointer;
}

.Button_link__1I5Q0.Button_small__2bc8s {
  font-size: 12px;
}

.Button_btn__2P1LO.Button_pro__39Hpa,
.Button_btn__2P1LO.Button_pro__39Hpa:hover {
  background-color: #ff9a4b;
}

.Button_btn__2P1LO.Button_pro__39Hpa:focus {
  box-shadow: 0 0 3px 1px #ff9a4b;
}

.Button_btn__2P1LO.Button_disabled__2CnIR,
.Button_btn__2P1LO.Button_disabled__2CnIR:hover {
  cursor: not-allowed;
  background-color: #58616b;
}

.Button_btn__2P1LO > svg {
  margin-right: 5px;
}

.Button_btn__2P1LO.Button_danger__1P-Zy {
  background: #fd3751;
  color: #fff;
}

.Button_btn__2P1LO.Button_danger__1P-Zy.Button_secondary__2nM_K {
  background: transparent;
  color: #fd3751;
  border: 1px solid #fd3751;
}

.Button_btn__2P1LO.Button_secondary__2nM_K {
  background: transparent;
  color: #0631ca;
  border: 1px solid #0631ca;
}

.Button_btn__2P1LO.Button_secondary__2nM_K.Button_pro__39Hpa {
  color: #ff9a4b;
  border: 1px solid #ff9a4b;
}

.Button_btn__2P1LO.Button_small__2bc8s {
  height: 36px;
  min-width: initial;
  padding: 5px 20px;
  font-size: 14px;
  line-height: initial;
}

a.Button_btn__2P1LO:hover {
  text-decoration: none;
}

.Button_btn__2P1LO.Button_wide__2fEq5 {
  width: 100%;
}

.Button_btn__2P1LO:focus {
  box-shadow: 0 0 3px 1px #0631ca;
}

.Button_btn__2P1LO:hover {
  background: #0633ff;
}

.Button_btn__2P1LO.Button_danger__1P-Zy:focus {
  box-shadow: 0 0 3px 1px #fd505a;
}

.Button_btn__2P1LO.Button_danger__1P-Zy:hover {
  background: #fd505a;
}

.Button_btn__2P1LO.Button_secondary__2nM_K:hover {
  background: #f4f4f4;
}

.ActionBar_actionBar__1yrii {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.ActionBar_error__ukARL {
    flex: 1 1;
    text-align: right;
    margin-right: 20px;
    color: #FD3751;
}
.Tabs_tabList__1svuQ {
    margin: 0;
    padding: 0;
    flex: 1 1;
}

li.Tabs_tab__4VusT {
    display: inline-flex;
    align-items: center;
}

li.Tabs_tab__4VusT > svg {
    margin-right: 5px;
    font-size: 14px;
}

li.Tabs_selectedTab__1Y-Lb {
    color: #153BD2;
    font-weight: bold;
    border-bottom: 3px solid #153BD2;
    height: 40px;
}
.Icons_delete__-M8cS {
  color: #fd3751;
}

.Icons_list__5rg-t {
  font-size: 12px;
}

.Icons_pro__3DCM0 {
  color: #ff9a4b;
}

.Pill_pill__24fRH {
  padding: 5px 10px;
  background-color: #b1cfff;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  border-radius: 12px;
}

.Dropzone_dropzone__2dnyi {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #BDD7F2;
    border-radius: 5px;
    min-height: 125px;
    outline: none;
}

.Dropzone_fileList__1FLfU {
    flex: 1 1;
}

.Dropzone_fileItem__FtSf7 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 10px 20px 0 20px;
}

.Dropzone_trashIcon__b4IiE {
    color: #FD3751;
    cursor: pointer;
}

.Dropzone_dropzone__2dnyi.Dropzone_empty__IYxoN {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: dashed;
}

.Dropzone_dropzone__2dnyi.Dropzone_active__1AnJR {
    background-color: #DAEDF6;
}

.Dropzone_uploadIcon__3IFwB {
    font-size: 36px;
    color: #0631CB;
}

.Dropzone_uploadBar__2aUMf {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-top: 1px solid #BDD7F2;
    margin-top: 10px;
}

.Dropzone_uploadBar__2aUMf svg {
    color: #0631CB;
    margin-right: 5px;
}
pre.Pre_pre__sG6tI {
  line-height: 20px;
  font-size: 16px;
  color: #58616b;
  background-color: #f8fbfd;
  margin: 0;
  padding: 16px 0;
  overflow-x: auto;
}

pre.Pre_inlinePre__37ktc {
  display: inline-block;
  background-color: #d4e5fe;
  color: #0631cb;
  padding: 0 5px;
}

pre.Pre_pre__sG6tI > div:before {
  counter-increment: line;
  display: inline-block;
  border-right: 1px solid #bdd7f2;
  padding-right: 10px;
  margin-right: 10px;
  color: #97aeb8;
  -webkit-user-select: none;
          user-select: none;
}

pre.Pre_pre__sG6tI > div:nth-of-type(-n + 999):before {
  content: counter(line);
}

pre.Pre_pre__sG6tI > div:nth-of-type(-n + 99):before {
  content: " " counter(line);
}

pre.Pre_pre__sG6tI > div:nth-of-type(-n + 9):before {
  content: "  " counter(line);
}

pre.ConsoleOutput_editableContainer__dLCF9 {
  min-height: 300px;
}

.ConsoleOutput_stdoutIgnore__QZQ7r {
  color: #97aeb8;
}

.ConsoleOutput_stdoutInput__2dDFI {
  background-color: #d4e5fe;
  color: #0631cb;
  cursor: pointer;
}

.ConsoleOutput_stdoutEquals__1jbqB {
  color: #58616b;
}

.ConsoleOutput_stdoutContains__3w1dl {
  background-color: #e8ecfd;
  color: #5c64ba;
  cursor: pointer;
}

.ConsoleOutput_popover__1YVyR {
  font-family: "Lato", sans-serif;
  background-color: #fff;
  color: #58616b;
  box-shadow: 0 0 10px 2px rgba(232, 232, 232, 1);
  padding: 10px;
  border-radius: 3px;
}

.ConsoleOutput_title__3949m {
  display: flex;
  justify-content: space-between;
}

.ConsoleOutput_toggleMode__1WRKI {
  display: flex;
  align-items: center;
  color: #0f1978;
  cursor: pointer;
}

.ConsoleOutput_toggleMode__1WRKI:hover {
  text-decoration: underline;
}

.ConsoleOutput_toggleMode__1WRKI > svg {
  margin-right: 3px;
}

.ConsoleOutput_line__1kHJT {
  position: relative;
}

.ConsoleOutput_configureLine__3cTfe {
  visibility: hidden;
  font-family: "Lato", sans-serif;
  position: absolute;
  left: 30px;
  top: 2px;
}

.ConsoleOutput_configureLine__3cTfe svg {
  color: #0f1978;
  cursor: pointer;
}

.ConsoleOutput_line__1kHJT:hover > .ConsoleOutput_configureLine__3cTfe {
  visibility: visible;
}

.ConsoleOutput_popover__1YVyR {
  background-color: #fff;
  padding: 10px;
}

.Input_text__1wRl0 {
  display: block;
  height: 36px;
  border: 1px solid #bacdd7;
  border-radius: 3px;
  min-width: 250px;
  padding: 0 10px;
  color: #58616b;
}

.Input_text__1wRl0.Input_compact__2J3-N {
  min-width: auto;
  width: 165px;
}

.Input_text__1wRl0.Input_wide__2twKQ {
  width: 100%;
}

.Input_helpIcon__2HHt3 {
  margin-left: 5px;
  font-size: 12px;
}

.Input_popover__2cLQd {
  text-align: left;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px #dadada;
  min-width: 200px;
}

.Input_text__1wRl0::-webkit-input-placeholder {
  color: #a9c0cf;
}

.Input_text__1wRl0::placeholder {
  color: #a9c0cf;
}

.OutputMatching_container__2g1XP {
    font-size: 14px;
}

.OutputMatching_actionBar__v3Ks6 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.OutputMatching_ioFiles__13vVf {
    display: flex;
    justify-content: space-between;
}

.OutputMatching_ioFiles__13vVf > div:first-child {
    margin-right: 20px;
}

.OutputMatching_ioFiles__13vVf > div {
    flex: 1 1;
}
.List_labelContainer__21RUC {
  display: flex;
  flex: 1 1;
}

.List_labelContainer__21RUC > div:first-child {
  margin-right: 10px;
}

.List_labelContainer__21RUC > div.List_labelContent__1n_Bp {
  width: 100%;
}

.List_item__g8b-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  border-bottom: 1px solid #dbedf6;
  padding: 20px 0;
}

.List_list__1m2e7 > .List_item__g8b-4:last-child {
  border-bottom: none;
}

.List_item__g8b-4.List_noBorder__h2RpH {
  border: none;
}

.List_actions__3JKTQ > span {
  margin-right: 15px;
  cursor: pointer;
}

.List_actions__3JKTQ > span:last-child {
  margin-right: 0;
}

.Accordion_panel__2Noxo {
    border-bottom: 1px solid #CECECE;
    padding: 20px;
}

.Accordion_panel__2Noxo.Accordion_compact__1tv8f {
    padding: 0;
}

.Accordion_panel__2Noxo:last-child {
    border-bottom: none;
}

.Accordion_panelOverview__YIb1Q {
    display: flex;
    justify-content: space-between;
}

.Accordion_panelOverview__YIb1Q > div {
    margin-right: 10px;
}

.Accordion_panelOverview__YIb1Q > div:last-child {
    margin-right: 0;
}

.Accordion_panelChevron__3vIz7 {
    display: inline-flex;
    justify-content: flex-end;
    margin-top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.Accordion_panelContent__30gNp {
    padding-top: 20px;
}
.WebUITests_newSequence__3ntSp {
  display: flex;
  align-items: flex-end;
}

.WebUITests_sequence__2Lmpu {
  margin: 0 -20px;
}

.WebUITests_sequenceStep__XYLO1 {
  background-color: #f8fbfd;
  padding: 20px;
}

.WebUITests_flex__3WL3E {
  display: flex;
}

.ResultSet_failedIcon__3vC0e {
    color: #FD3751;
    margin-right: 5px;
}

.ResultSet_successIcon__2XW19 {
    color: #1CD7C3;
    margin-right: 5px;
}

.ResultSet_result__15MXG {
    display: flex;
    align-items: center;
}

.ResultSet_actualOutput__zjkuz {
    margin-left: 20px;
}
.Indicators_indicator__1eG2m {
  display: inline-block;
  min-height: 12px;
  min-width: 12px;
  border-radius: 6px;
  border: 2px solid #e4e4e4;
  margin-right: 5px;
  transition: background-color 200ms linear;
}

.Indicators_indicator__1eG2m.Indicators_neutral__1A3pC {
  background-color: #97aeb8;
}

.Indicators_indicator__1eG2m.Indicators_failed__10wzw {
  background-color: #fd3751;
}

.Indicators_indicator__1eG2m.Indicators_success__2kl7o {
  background-color: #1cd7c3;
}

.Indicators_indicator__1eG2m.Indicators_inProgress__2QhQ1 {
  background-color: #0631cb;
  -webkit-animation: Indicators_inProgress__2QhQ1 1s infinite;
          animation: Indicators_inProgress__2QhQ1 1s infinite;
}

@-webkit-keyframes Indicators_inProgress__2QhQ1 {
  0% {
    border-color: #e4e4e4;
  }
  50% {
    border-color: #0631cb;
  }
  100% {
    border-color: #e4e4e4;
  }
}

@keyframes Indicators_inProgress__2QhQ1 {
  0% {
    border-color: #e4e4e4;
  }
  50% {
    border-color: #0631cb;
  }
  100% {
    border-color: #e4e4e4;
  }
}

.TestCaseSet_tabListContainer__3QdPe {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbedf6;
  padding: 8px 20px 0 20px;
}

.TestCaseSet_tabPanelContainer__zj83m {
  padding: 20px;
}

.TestCaseSet_tabPanelContainer__zj83m.TestCaseSet_docked__3D4K0 {
  height: 250px;
  overflow-y: auto;
}

.TestCaseSet_tabPanelContainer__zj83m.TestCaseSet_docked__3D4K0.TestCaseSet_collapsed__3GSXh {
  display: none;
}

.TestCaseSet_activeView__1DlPy {
  font-weight: bold;
}

.TestCaseSet_inactiveView__1uWg5 {
  color: #5270ff;
}

.TestCaseSet_collapseIcon__6FraZ {
  font-size: 12px;
  cursor: pointer;
  color: #0b212f;
}

.TextArea_container__2dJwh {
    width: 100%;
    text-align: left;
}

.TextArea_text__2EOu2 {
    display: block;
    height: 36px;
    border: 1px solid #BACDD7;
    border-radius: 3px;
    padding: 10px;
    color: #58616B;
    width: 100%;
    min-height: 100px;
}

.TextArea_text__2EOu2::-webkit-input-placeholder {
    color: #a9c0cf;
}

.TextArea_text__2EOu2::placeholder {
    color: #a9c0cf;
}
.Support_support__1Z6UD {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #2734a8;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}

.Support_centered__2yLvG {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.LoadingBar_loadingBar__10J06 {
    position: fixed;
    top: 0;
    left: 0;
    height: 1px;
    background-color: #0631CA;
    box-shadow: 0 0 1px 1px #0631CA;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    transition: width 300ms ease-out;
}

.BaseLayout_container__10J_M {
  display: flex;
  min-height: 100%;
  min-width: 1000px;
}

.BaseLayout_main__3b6ux {
  display: flex;
  flex: 1 1;
  margin-left: 210px;
}

.BaseLayout_centered__A3S6d {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BaseLayout_nav__1BKQU {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-basis: 210px;
  width: 210px;
  flex-shrink: 0;
  background-color: #0e1352;
  padding: 20px;
  overflow-y: auto;
  z-index: 999;
}

.BaseLayout_coreNav__3-K2z {
  flex: 1 1;
  padding-bottom: 50px;
}

.BaseLayout_navLink__1UXO5 {
  display: flex;
  height: 32px;
  align-items: center;
  color: #fff;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BaseLayout_activeNavLink__dIvYd {
  color: #8c96ec;
}

.BaseLayout_navTitleContainer__39e-5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h5.BaseLayout_navTitle__2Gfcj {
  color: #8c96ec;
}

.BaseLayout_navHr__314U4 {
  border-color: #2834a8;
  margin: 10px -20px;
}

.BaseLayout_addCourse__3e1Ya {
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.BaseLayout_content__1vdGF {
  padding: 20px 40px;
  flex-grow: 1;
}

.BaseLayout_sidebar__cE10y {
  flex-basis: 300px;
  flex-shrink: 0;
  padding: 20px 20px 20px 0;
}

@media (max-width: 1260px) {
  .BaseLayout_sidebar__cE10y {
    display: none;
  }
}

.BaseLayout_accountSubNav__5MduH {
  margin: -20px;
  padding: 10px 20px;
  background-color: #2834a8;
}

.BaseLayout_accountSubNavSkeleton__BMEcH {
  height: 66px;
  margin: -20px;
  background-color: #2834a8;
}

h5.BaseLayout_lightText__1f4Lu,
span.BaseLayout_lightText__1f4Lu,
.BaseLayout_lightText__1f4Lu {
  color: #fff;
}

.BaseLayout_contentHeader__18Gc7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BaseLayout_actionsContainer__25iI0 {
  display: flex;
}

.BaseLayout_pill__efQc0 {
  color: #1cd7c3;
  border-radius: 12px;
  font-size: 14px;
  margin-top: 2px;
}

.CreateCourseForm_container__lv3Mx {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.CreateCourseForm_back__11Urt {
  position: absolute;
  top: 20px;
  left: 20px;
}

.CreateCourseForm_content__1gzD6 {
  width: 440px;
  text-align: center;
  padding: 80px 0;
}

.CreateCourseForm_form__3wODq {
  color: #000;
  text-align: left;
  padding: 0 20px;
}

.CreateCourseForm_flex__1IQu8 {
  display: flex;
  justify-content: space-between;
}

.CreateCourseForm_actionBar__5s2U- {
  margin: 0 auto;
  width: 250px;
}

.CreateCourseForm_subtitle__1iA_K {
  margin: 2px 0 0 18px;
  font-size: 14px;
}

.CreateCourseForm_seatsEditor__1KnCP {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.CreateCourseForm_seatsEditor__1KnCP > div:nth-child(1) {
  flex: 1 1;
}

.CreateCourseForm_seatsEditor__1KnCP > div:nth-child(2) {
  margin-right: 20px;
}

input[type="text"].CreateCourseForm_studentsInput__r5PRU {
  width: 60px;
}

.CreateCourseForm_fontSm__2-ECC {
  font-size: 14px;
}

.CreateCourseForm_totalPrice__3olM4 {
  padding-bottom: 6px;
  font-weight: bold;
  min-width: 50px;
  text-align: right;
}

.CreateCourseForm_cardForm__1i4xv {
  border: 1px solid #bacdd7;
  border-radius: 4px;
  padding: 10px;
}

.react-datepicker {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.react-datepicker__time-container,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100px;
}

.react-datepicker__navigation--next--with-time {
  right: 110px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #0631ca;
}

.AddressForm_formFlex__1lsHM {
  display: flex;
}

.AddressForm_formFlex__1lsHM input {
  min-width: auto;
  width: 140px !important;
}

.ExerciseOverviewPage_testCaseSetContainer__2MdlH {
    box-shadow: 0 0 10px 2px rgba(232,232,232,1);
    border-radius: 5px;
}

.ExerciseOverviewPage_sidebar__36N4d {
    display: flex;
    align-items: flex-end;
    height: 100%;
}
.Node_label__352ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border-radius: 2px;
}

.Node_label__352ul > div {
  display: flex;
}

.Node_indentation__24oNx {
  padding-left: 20px;
}

.Node_file__2gNyI {
}

.Node_label__352ul.Node_file__2gNyI:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.05);
}

.Node_labelIcon__DNDgz {
  margin-right: 5px;
}

.Node_fileActionBtn__1Egvu {
  margin-left: 10px;
  visibility: hidden;
}

.Node_label__352ul.Node_file__2gNyI:hover .Node_fileActionBtn__1Egvu {
  visibility: visible;
}

.ConfirmAction_popover__jkE5- {
    text-align: left;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px #dadada;
    min-width: 200px;
}

.ConfirmAction_wide__2Wu6j {
    min-width: 300px;
}

.ConfirmAction_error__3pwlL {
    color: #fd505a;
}
.FileBrowser_container__2ZIFU {
  min-width: 220px;
  background: #e0f0f7;
  overflow: auto;
}

.FileBrowser_actionBar__2pVlE {
  display: flex;
  align-items: center;
  height: 40px;
  background: #e8f3f9;
  padding: 5px 20px;
  border-bottom: 2px solid #ddeef6;
}

.FileBrowser_padded__18HhL {
  padding: 20px;
}

.FileBrowser_newFileContainer__2YyKD {
  background-color: #fff;
  color: #58616b;
  box-shadow: 0 0 10px 2px rgba(232, 232, 232, 1);
  padding: 10px;
  border-radius: 3px;
}

.Editor_container__unHqr {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #f8fbfd;
}

.Editor_padded__3vPGK {
  padding: 20px;
}

.Editor_tabs__2Dv4x {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
  border-bottom: 2px solid #eaf4f9;
  background-color: #fff;
  font-size: 14px;
}

.Editor_saveStatus__3rbkQ {
  margin-left: 10px;
  margin-top: 4px;
  font-size: 12px;
  color: #1d6b99;
}

.CodeEditor_container__2kaFe {
    display: flex;
    height: 100%;
    width: 100%;
    color: #0B2230;
}

.AttemptPage_container__1jk8r {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 1000px;
}

.AttemptPage_header__JzRsr {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background: #0e1352;
}

.AttemptPage_header__JzRsr,
.AttemptPage_header__JzRsr h1,
.AttemptPage_header__JzRsr h3 {
  color: #fff;
  font-weight: normal;
}

.AttemptPage_header__JzRsr > div {
  margin-right: 15px;
}

.AttemptPage_split__20P9a {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

.AttemptPage_split__20P9a > div:nth-child(1) {
  flex: 1 1;
}

.AttemptPage_workspaceTestCasesContainer__2QpAu {
  display: flex;
  flex-direction: column;
}

.AttemptPage_workspaceContainer__3RZs_ {
  position: relative;
}

.AttemptPage_runTestsContainer__2ur6H {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.AttemptPage_exerciseOverviewContainer__e8Aqd {
  display: flex;
  flex-direction: column;
  flex-basis: 440px;
  background-color: #f1f7fc;
  max-height: 100%;
  overflow-y: auto;
}

.AttemptPage_exerciseOverviewToolbar__2-Z_i {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 40px;
  padding: 0 20px;
  border-bottom: 2px solid #eaf4f9;
  background-color: #fff;
  font-size: 14px;
}

.AttemptPage_exerciseOverview__CD-xk {
  flex: 1 1;
  padding: 20px;
  color: #000;
  border-left: 1px solid #dbedf6;
  overflow: auto;
}

.AttemptPage_testCasesContainer__1YyAl {
  border-top: 1px solid #dbedf6;
  z-index: 999;
}

.AttemptPage_actionContainer__3jHkH {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1;
  flex-basis: 100px;
  flex-shrink: 0;
  border-top: 1px solid #dbedf6;
}

.ViewToggles_inactive__1VM3r {
  color: #000;
}

.Card_card__2EyWL {
    border-radius: 5px;
    box-shadow: 0 0 5px #dadada;
}
.NestedNav_nav__2XPXK {
    display: flex;
    align-items: center;
    background-color: #0F1978;
    height: 50px;
    border-radius: 5px;
    padding: 0 20px;
    color: #fff;
}

.NestedNav_contained__WuTkt {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.NestedNav_nav__2XPXK > a {
    display: flex;
    align-items: center;
    color: #fff;
}

.NestedNav_nav__2XPXK > a:hover {
    text-decoration: none;
}

.NestedNav_nav__2XPXK > a > * {
    margin-right: 10px;
}
.CounterPill_counterPill__PrM_i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #D6DAFF;
    color: #0E1353;
    font-weight: bold;
}
.HelpSection_container__5OJRX {
  display: flex;
  font-size: 14px;
  line-height: 18px;
}

.HelpSection_icon__1qggl {
  color: #a3a3a4;
  font-size: 16px;
  margin-top: 2px;
}

.HTMLEditor_htmlEditor__TuE3- {
    min-height: 300px;
}
.EditExercise_actionBar__24Ido {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Checkbox_container__z9CgI {
  display: inline-block;
  cursor: default;
}

.Checkbox_checkbox__q4JAY {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #466ada;
  border-radius: 5px;
  font-size: 12px;
  color: #466ada;
}

.Checkbox_checkbox__q4JAY.Checkbox_pro__28t7v {
  border: 1px solid #ff9a4b;
  color: #ff9a4b;
}

.Checkbox_checkbox__q4JAY.Checkbox_disabled__1SZ4_ {
  background: #f1f7fc;
  cursor: not-allowed;
}

.Checkbox_label__2Uezj {
  margin-left: 5px;
}

.MultiSelector_container__2sQem {
    display: flex;
}

.MultiSelector_container__2sQem > .MultiSelector_column__2Bw9D {
    margin-right: 10px;
}

.MultiSelector_container__2sQem > .MultiSelector_column__2Bw9D:last-child {
    margin-right: 0;
}

.MultiSelector_column__2Bw9D {
    border: 1px solid #DBEDF6;
    border-radius: 5px;
}

.MultiSelector_item__1ko0n {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 10px;
    border-bottom: 1px solid #DBEDF6;
}

.MultiSelector_item__1ko0n:last-child {
    border-bottom: none;
}

.MultiSelector_item__1ko0n > input[type="checkbox"] {
    margin-right: 5px;
}
.TestSuiteEditor_newTestCase__3WQH_ {
    display: flex;
    align-items: flex-end;
}

.TestSuiteEditor_newTestCase__3WQH_ > div {
    margin-right: 10px;
}

.TestSuiteEditor_newTestCase__3WQH_ > div:last-child {
    margin-right: 0;
}

.TestSuiteEditor_actionBar__3LQ5w {
    display: flex;
    justify-content: flex-end;
}

.TestSuiteEditor_action__o20y2 {
    color: #0631CB;
}

.TestSuiteEditor_deleteAction__3UOXv {
    color: #FD3751;
}

.TestSuiteEditor_deleteAction__3UOXv.TestSuiteEditor_disabled__2taOq {
    color: #58616B;
    cursor: wait;
}

.EditTestCase_testCaseEditor__3VHkh {
    padding: 20px;
}

.EditTestCase_actionBar__jNfzq {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.EditTestCase_metadataEditor__3h2u6 {
    padding: 20px;
}

.EditTestCase_metadataActionBar__2aScI {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.EditTestCase_metadataEditorForm__2TJ4- {
    display: flex;
}

.EditTestCase_metadataEditorForm__2TJ4- > * {
    margin-right: 20px;
}
.ExerciseSet_createExercise__lM6r0 {
    display: flex;
    align-items: flex-end;
}

.ExerciseSet_createExercise__lM6r0 > div {
    margin-right: 10px;
}

.ExerciseSet_createExercise__lM6r0 > div:last-child {
    margin-right: 0;
}

.ExerciseSet_actionBar__14tqr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ExerciseSet_action__uaz4K {
    color: #0631CB;
}

.ExerciseSet_deleteAction__1WCpU {
    color: #FD3751;
}
.CourseOverview_cardContainer__HN_pp {
    padding: 10px 20px;
}

.CourseOverview_labIcon__mLeuM {
    font-size: 12px;
    margin-right: 3px;
}
.NullStateCard_container__PzJ6J {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  min-height: 300px;
}

.NullStateCard_container__PzJ6J.NullStateCard_compact__12-RP {
  min-height: unset;
}

.NullStateCard_pro__3sqMj {
  background-color: #ffe2d1;
  border: 1px dashed #ff9a4b;
  box-shadow: none;
}

.NullStateCard_content__3_d9O {
  max-width: 500px;
}

.NullStateCard_illustration__3F_o1 {
  width: 150px;
}

.NullStateCard_background__1LL9K {
  position: absolute;
  left: 0;
  max-width: 50%;
  max-height: 100%;
  z-index: -1;
  opacity: 0.6;
}

.Notification_notification__3fkGv {
  background-color: #fff0e9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  font-size: 14px;
}

.AnonymousLayout2_page__1uKvc {
    display: flex;
    min-height: 100%;
    min-width: 1000px;
}

.AnonymousLayout2_side__1cSVg {
    display: flex;
    flex-direction: column;
    background-color: #D8E7FE;
    width: 40%;
    padding: 20px;
}

.AnonymousLayout2_sideContentContainer__25Whe {
    display: flex;
    justify-content: center;
}

.AnonymousLayout2_sideContent__2Xm_O {
    max-width: 400px;
    text-align: center;
}

.AnonymousLayout2_content__3kdzl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 40px 0;
}
.Login_loginForm__3zk_h {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    padding: 40px 0;
}

.Login_centered__1Glne {
    text-align: center;
}

.Login_forgotPassword__1qnPd {
    width: 250px;
    text-align: left;
    font-size: 14px;
}
.AnonymousLayout1_page__20JqS {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 1000px;
  padding: 20px;
}

.AnonymousLayout1_contentContainer__1DQPr {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.AnonymousLayout1_content__ViFSM {
  width: 500px;
}

.AnonymousLayout1_center__2Bld1 {
  text-align: center;
}

.AnonymousLayout1_card__39he2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 300px;
}

.Signup_center__3FgSR {
    text-align: center;
}

.SignupVerify_center__2sVPQ {
    text-align: center;
}

.SignupVerify_resendCode__1QbSK {
    width: 250px;
    text-align: left;
    font-size: 14px;
}
.ResetPassword_center__27D_I {
    text-align: center;
}

.ResetPassword_explainer__PV1u7 {
    text-align: center;
    width: 400px;
}
table.Table_table__1ktAz {
    width: 100%;
    border-collapse: collapse;
}

.Table_table__1ktAz > thead > tr > th {
    padding: 0 20px;
    color: #7AA1C3;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    background-color: #F1F7FC;
}

.Table_table__1ktAz tr {
    height: 40px;
}

.Table_table__1ktAz td {
    padding: 0 20px;
    text-align: center;
}

table.Table_table__1ktAz, table.Table_table__1ktAz td, table.Table_table__1ktAz th {
    border: 1px solid #DEEBF8;
}

table.Table_table__1ktAz.Table_listBorder__3RGYx, table.Table_table__1ktAz.Table_listBorder__3RGYx td, table.Table_table__1ktAz.Table_listBorder__3RGYx th {
    border: none;
    border-bottom: 1px solid #DEEBF8;
}

th.Table_left__3j2YE, td.Table_left__3j2YE {
    text-align: left;
}

th.Table_right__tNqCo, td.Table_right__tNqCo {
    text-align: right;
}

.Table_table__1ktAz th.Table_compact__2b8jh, .Table_table__1ktAz td.Table_compact__2b8jh {
    padding: 0;
}

.Table_table__1ktAz td.Table_highlight__1z4n7:hover {
    background-color: #F1F7FC;
}

.Table_table__1ktAz > thead > tr > th.Table_empty__1dqV7 {
    background-color: #fff;
}
.Gradebook_failed__3Hkpp {
    color: #FF3750;
}

.Gradebook_passed__1v_YS {
    color: #09EDDB;
}

.Gradebook_attemptResult__397Xm {
    position: relative;
    width: 100%;
    height: 100%;
}

.Gradebook_attemptResult__397Xm > a.Gradebook_attemptLink__1dTuZ {
    position: absolute;
    right: 0;
    visibility: hidden;
    font-size: 14px;
}

.Gradebook_attemptResult__397Xm:hover > a.Gradebook_attemptLink__1dTuZ {
    visibility: visible;
}
.Assignment_actionTitle__3s3Yb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CourseRoster_enroller__2nfJX {
    display: flex;
}

.CourseRoster_enroller__2nfJX .CourseRoster_invite__2LAxb {
    background-color: #D8E7FF;
    padding: 20px;
}

.CourseRoster_enroller__2nfJX .CourseRoster_inviteCode__2vVN5 {
    color: #0631CB;
    font-size: 28px;
    font-weight: bold;
}

.CourseRoster_enroller__2nfJX .CourseRoster_inviteCode__2vVN5 > svg {
    font-size: 20px;
}

.CourseRoster_enroller__2nfJX .CourseRoster_enroll__3uCTf {
    padding: 20px
}

.CourseRoster_enroller__2nfJX .CourseRoster_enrollForm__3n5N0 {
    display: flex;
}
.ExerciseSets_actionTitle__2mLgG {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ExerciseSets_exercisesCount__1k81u {
  display: flex;
  align-items: center;
}

.ExerciseSets_createExerciseSet__17RPg {
  display: flex;
  align-items: flex-end;
  text-align: left;
}

.ExerciseSets_centered__3YeyO {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.Profile_padded__mDCRg {
    padding: 20px;
}
.CourseManage_padded__1XUKj {
    padding: 20px;
}

.CourseManage_renameContainer__18kX5 {
    display: flex;
    align-items: flex-end;
}
.GettingStarted_panel__2I3Lu {
  padding-left: 0;
  padding-right: 0;
}

.GettingStarted_flex__3CYAq {
  display: flex;
}

.GettingStarted_videosContainer__3vda_ {
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
}

.GettingStarted_videosContainer__3vda_ iframe {
  flex-basis: 600px;
  height: 330px;
  flex-shrink: 0;
  padding: 20px 0;
}

.CourseEnroll_explainer__2Baox {
  text-align: center;
  width: 400px;
}

.CourseEnroll_center__3Yhga {
  text-align: center;
}

.CourseEnroll_cardForm__pr11u {
  border: 1px solid #90989f;
  border-radius: 4px;
  padding: 10px;
}

.SignupSurvey_center__1B2vq {
  text-align: center;
}

.SignupSurvey_resendCode__vEDqQ {
  width: 250px;
  text-align: left;
  font-size: 14px;
}

.SampleExerciseSets_actionTitle__OtaKb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SampleExerciseSets_exercisesCount__2H3W9 {
  display: flex;
  align-items: center;
}

.CS50ExerciseSets_actionTitle__2spyW {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CS50ExerciseSets_exercisesCount__2La1W {
  display: flex;
  align-items: center;
}

.CS50ExerciseSets_plusIcon__8jhgs {
  font-size: 10px;
  margin-top: 2px;
}

html,
body,
#root {
  height: 100%;
  font-family: "Lato", sans-serif;
  color: #58616b;
  line-height: 22px;
}

* {
  box-sizing: border-box;
}

a {
  color: #153bd2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

hr {
  border: 0;
  border-bottom: 1px solid #dbedf6;
}

pre {
  margin: 0;
}

